import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {

    const isAuthenticated = localStorage.getItem("auth");

    return (
        isAuthenticated ? (
                <Outlet />
          ) : (
            <Navigate to="/" replace/>
          )
    );
};


export default PrivateRoute;