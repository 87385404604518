import React from 'react'
import { SmartAppLogo } from '../assets/images'
import IleLogo from '../assets/images/ileLogo.png'
import './dashboardTest.css'

const Header = (props) => {
    return (
        <div className='header'>

            {/* <SmartAppLogo /> */}
            <p className='titleTxt'>{props.title}</p>
            <div className='imageContainer'>
                {/* <img src={IleLogo} alt='' ></img> */}
            </div>


        </div>
    )
}

export default Header
