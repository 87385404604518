// src/Component/MainLayout.js
import React, { useContext } from "react";
import SideMenu from "./SideMenu";
import "./layout.css";
import Header from "./Header";
import { HeaderContext } from "./Context/Context";
import { Outlet } from "react-router";

const Layout = () => {

    // const { pageTitle } = useContext(HeaderContext)

  return (
    <div className="mainContainer">
      <Header/>
      <div className="main-wrapper">
              <SideMenu />
              <div className="content-wrapper" >
                  {/* <h1>{pageTitle}</h1> */}
              <div className="content" >
                  <Outlet/>
                  </div>
            </div>
      </div>
    </div>
  );
};

export default Layout;
