import React, { useState, useEffect, } from 'react'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import './Analytics.css'
import axiosInstance from '../../../Services/api.js'
const BiAnalytics = ({reportId , dataSetId}) => {
 
    const [powerBiDetails, setPowerBiDetails] = useState(null)
    const fetchPowerbiDetails = async () => {
        const details = await axiosInstance.post('analytics/get_embed_token', {
            'reportId': reportId
        })
        setPowerBiDetails(details)
    }
    const powerbiRefresh = async () => {

        const details = await axiosInstance.post('analytics/powerbi_refresh', {
            'datasetId': process.env.REACT_APP_BI_SKYHIGH_DATASETID
            // process.env.REACT_APP_BI_ACCENTURE_REPORT
        })
        // console.log(details);
    }
    
    useEffect(() => {
        
        if (dataSetId !== null) {
            powerbiRefresh();
        }
        fetchPowerbiDetails();

    }, [reportId, dataSetId]);

    return (
        <div style={{ height: '100%', width: '100%' }} className="bodyContainer">
            <div style={{ height: '100%', width: '100%' }} className="">
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        // id: powerBiDetails?.embedUrl[0].reportId,
                        id: reportId,
                        // embedUrl: powerBiDetails?.embedUrl[0].embedUrl,
                        embedUrl: powerBiDetails?.embedUrl[0].embedUrl,
                        accessToken: powerBiDetails?.accessToken,
                        tokenType: models.TokenType.Embed, 
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                            // background: models.BackgroundType.Transparent,
                        },
                    }}

                    eventHandlers={
                        new Map([
                            ['loaded', function () { 
                                console.log('Loaded report powerBi')
                            }],
                            ['rendered', function () { }],
                            ['error', function (event) { console.log(event.detail); }],
                            ['visualClicked', () => console.log('visual clicked')],
                            ['pageChanged', (event) => console.log(event)],
                        ])
                    }

                    cssClassName={"embed-container"}
                    getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;

                    }}
                />
            </div>
        </div>
    )
}

export default BiAnalytics;
