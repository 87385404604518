import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import loginBanner from "../assets/images/analytics.jpg"
import { toast } from 'react-toastify';
import { config } from "../config"; 
// import { showToaster } from "../services/helper";
// import { login } from "../services/api-servics";

export default function Login() {

  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!credentials.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      errors.email = "Email address is invalid";
    }
    if (!credentials.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const handleInpuChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleLogin = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      const clientConfig = config[process.env.REACT_APP_DEPLOYMENT_TARGET]
      if (credentials.email === clientConfig.CREDENTIALS.email && credentials.password === clientConfig.CREDENTIALS.password) {
        localStorage.setItem('auth', credentials.email)
        const home = clientConfig.ROUTES.home
        navigate(home);

      } else {
        // alert("Invalid credentials");
        toast.error('Invalid Credentials', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    // <div className="login">
    //   <section class="hi-100">
    //     <div class="container-fluid h-custom">
    //       <div class="row d-flex ">
    //         <div class="col-md-6 d-flex justify-content-center">
    //           {/* <img
    //             className="login-banner"
    //             // style={{ width: "100%", height: "80%" }}
    //             src={loginImage}
    //             alt="Login Image"
    //           /> */}
    //         </div>

    //         {/* <div class="col-md-6"></div> */}
    //       </div>
    //     </div>
    //   </section>
    // </div>

    <div className="login">
      <div className="login-banner">
        <img src={loginBanner} alt="" />
      </div>
      <div className="login-form">
        <div className="form-section">
          <h1>Log in to your Account </h1>
          {/* <h4>Unlock your journey from here</h4> */}
          <div className="input-field-wrapper">
            {/* <label> Email </label> */}
            <input className="input-field" type="text" placeholder="Email" name="email" onChange={(e) => handleInpuChange(e)} />
            {/* <label> Password </label> */}
            <input className="input-field" type="password" placeholder="Password" name="password" onChange={(e) => handleInpuChange(e)} />
          </div>
          <button className="signin-button" onClick={handleLogin}> Sign in </button>
        </div>
        {/* <div className="brand-logo">
            <p> Powered by </p>
             <img className="company-logo" src={comp_logo} alt="" />
        </div> */}
      </div>
    </div>
  );
}
