import Login from "./Component/Login";
// import './App.css'
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./lib/privateRoute";
import Layout from "./Component/Layout";
import { config } from "./config"; 
import BiAnalytics from "./Pages/Analytics/BiAnalytics/BiAnalytics";



function App() {

const menu = config[process.env.REACT_APP_DEPLOYMENT_TARGET]

  return (
    <>
     <ToastContainer
        className="customToast"
        autoClose={1}
        hideProgressBar={false}
        theme="dark"
      />
    <BrowserRouter>
      <Routes>
        <Route path={config[process.env.REACT_APP_DEPLOYMENT_TARGET].ROUTES.login} exact element={<Login />} />
        {/* <HeaderProvider> */}
        <Route element={<PrivateRoute/>} >
        <Route element={<Layout />}>
                {menu.MENUS.map((routeObj, index) =>
                        <Route
                            key={routeObj.key}
                            path={routeObj.path}
                            element={<BiAnalytics reportId = {routeObj.reportId}  dataSetId = {routeObj.dataSetId} />}
                        />
                )}
          </Route>
        </Route>
        {/* </HeaderProvider> */}
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
